import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Card from './Card';

const CustomerCard = ({ customer }) => (
  <Card className="mb-8">
    <p className="text-xl font-semibold">
      <FormattedMessage id={`review.${customer.id}.title`} />
    </p>
    <p className="mt-6">
      <FormattedMessage id={`review.${customer.id}.body`}>
        {txt => <span dangerouslySetInnerHTML={{ __html: txt }} />}
      </FormattedMessage>
    </p>
    <div className="flex items-center mt-8">
      <img className="w-12 h-12 mr-4 rounded-full" src={customer.image} alt="" />
      <div>
        <p>
          <FormattedMessage id={`review.${customer.id}.name`} />
        </p>
        <p className="text-sm text-gray-600">
          <FormattedMessage id={`review.${customer.id}.footer`} />
        </p>
      </div>
    </div>
  </Card>
);

export default CustomerCard;
