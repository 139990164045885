import AvatarMan1 from '../images/avatars/man-1.jpg';
import AvatarMan2 from '../images/avatars/man-2.jpg';
import AvatarWoman3 from '../images/avatars/woman-3.jpg';

export default [
  {
    id: 'one',
    image: AvatarMan1
  },
  {
    id: 'two',
    image: AvatarMan2
  },
  {
    id: 'three',
    image: AvatarWoman3
  }
];
