import React from 'react';

const Card = ({ className, children }) => (
  <div
    className={`p-10 rounded-lg border border-solid bg-background border-gray-200 shadow-xl ${className}`}
    style={
      {
        // boxShadow: '0 10px 28px rgba(0,0,0,.08)'
      }
    }
  >
    {children}
  </div>
);

export default Card;
