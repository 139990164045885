import React from 'react';
import {
  useIntl,
  injectIntl,
  Link,
  FormattedMessage,
  changeLocale,
  IntlCache,
  IntlConfig,
  IntlContext,
  IntlContextConsumer
} from 'gatsby-plugin-intl';

import SEO from '../components/seo';
import ButtonLink from '../components/buttons/ButtonLink';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import customerData from '../data/customer-data';

import FrameLaptop from '../images/frames/laptop.png';
import FrameSamsungS9 from '../images/frames/samsung-s9.png';

import ScreenshotPcOverview from '../images/screenshots/pc/overview.png';
import ScreenshotTableAll from '../images/screenshots/mobile/table-all.png';
import ScreenshotGraph from '../images/screenshots/mobile/graph.png';
import ScreenshotAddMeasurement from '../images/screenshots/mobile/add-measurement.png';

export default () => {
  // changeLocale('en', 'xx');

  return (
    <Layout>
      <SEO title="BPT - Blood Pressure Tracking" />
      <section className="pt-20 md:pt-40">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/2">
            <h1 className="text-4xl sm:text-5xl xl:text-5xl mb-4 font-bold leading-none">
              <FormattedMessage id="intro.page.title">
                {txt => <span dangerouslySetInnerHTML={{ __html: txt }} />}
              </FormattedMessage>
            </h1>
            <h1 className="text-3xl sm:text-4xl lg:text-4xl xl:text-5xl font-bold leading-none">
              <FormattedMessage id="intro.page.name">
                {txt => <span dangerouslySetInnerHTML={{ __html: txt }} />}
              </FormattedMessage>
            </h1>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              <FormattedMessage id="intro.caption">
                {txt => <span dangerouslySetInnerHTML={{ __html: txt }} />}
              </FormattedMessage>
            </p>
            <p className="intro-buttons mt-8 md:mt-12 flex flex-col sm:flex-row sm:-mx-3 mt-12">
              <ButtonLink
                size="lg"
                className="intro-buttons-button"
                href="https://app.blood-pressure-tracking.com/register"
              >
                <FormattedMessage id="account.sign_up" />
              </ButtonLink>
              <div
                className="intro-buttons-button intro-buttons-button--google-play py-4 lg:py-3 py-4 px-20 bg-white hover:bg-highlight border-2 border-highlight rounded"
                role="button"
                aria-label="button-google-play"
                tabIndex={0}
                onClick={() => {
                  window.open(
                    `https://backend.blood-pressure-tracking.com/applications/android/bpt-1.0.2.apk`,
                    '_self'
                  );
                }}
                onKeyDown={() => {}}
              />
            </p>
            <p className="mt-4 text-light">
              <FormattedMessage id="account.already_have_account" />{' '}
              <a
                className="text-primary underline"
                href="https://app.blood-pressure-tracking.com/login"
              >
                <FormattedMessage id="account.sign_in" />
              </a>
            </p>
          </div>
          <div className="relative lg:w-1/2">
            <img className="frame frame--laptop" src={FrameLaptop} alt="" />
            <img className="screenshot screenshot--laptop" src={ScreenshotPcOverview} alt="" />
          </div>
        </div>
      </section>
      <section id="features" className="pt-20 pb-10 lg:pb-40 lg:pt-48">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">
            <FormattedMessage id="feature.title" />
          </h2>
          <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <p className="font-semibold text-xl">
                  <FormattedMessage id="feature.tracking.title" />
                </p>
                <p className="mt-4">
                  <FormattedMessage id="feature.tracking.body">
                    {txt => <span dangerouslySetInnerHTML={{ __html: txt }} />}
                  </FormattedMessage>
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <p className="font-semibold text-xl">
                  <FormattedMessage id="feature.overview.title" />
                </p>
                <p className="mt-4">
                  <FormattedMessage id="feature.overview.body">
                    {txt => <span dangerouslySetInnerHTML={{ __html: txt }} />}
                  </FormattedMessage>
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <p className="font-semibold text-xl">
                  <FormattedMessage id="feature.manage.title" />
                </p>
                <p className="mt-4">
                  <FormattedMessage id="feature.manage.body">
                    {txt => <span dangerouslySetInnerHTML={{ __html: txt }} />}
                  </FormattedMessage>
                </p>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <SplitSection
        id="services"
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-2xl lg:text-3xl font-semibold leading-tight">
              <FormattedMessage id="service.one.title" />
            </h3>
            <p className="mt-4 lg:mt-8 text-lg lg:text-xl font-light leading-relaxed">
              <FormattedMessage id="service.one.body">
                {txt => <span dangerouslySetInnerHTML={{ __html: txt }} />}
              </FormattedMessage>
            </p>
          </div>
        }
        secondarySlot={
          <>
            <img className="frame frame--samsung" src={FrameSamsungS9} alt="" />
            <img className="screenshot screenshot--samsung" src={ScreenshotTableAll} alt="" />
          </>
        }
      />
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-2xl lg:text-3xl font-semibold leading-tight">
              <FormattedMessage id="service.two.title" />
            </h3>
            <p className="mt-4 lg:mt-8 text-lg lg:text-xl font-light leading-relaxed">
              <FormattedMessage id="service.two.body">
                {txt => <span dangerouslySetInnerHTML={{ __html: txt }} />}
              </FormattedMessage>
            </p>
          </div>
        }
        secondarySlot={
          <>
            <img className="frame frame--samsung" src={FrameSamsungS9} alt="" />
            <img className="screenshot screenshot--samsung" src={ScreenshotGraph} alt="" />
          </>
        }
      />
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-2xl lg:text-3xl font-semibold leading-tight">
              <FormattedMessage id="service.three.title" />
            </h3>
            <p className="mt-4 lg:mt-8 text-lg lg:text-xl font-light leading-relaxed">
              <FormattedMessage id="service.three.body">
                {txt => <span dangerouslySetInnerHTML={{ __html: txt }} />}
              </FormattedMessage>
            </p>
          </div>
        }
        secondarySlot={
          <>
            <img className="frame frame--samsung" src={FrameSamsungS9} alt="" />
            <img className="screenshot screenshot--samsung" src={ScreenshotAddMeasurement} alt="" />
          </>
        }
      />
      {/* <section id="stats" className="py-20 lg:pt-32"> */}
      {/*  <div className="container mx-auto text-center"> */}
      {/*    <LabelText className="text-gray-600">Our customers get results</LabelText> */}
      {/*    <div className="flex flex-col sm:flex-row mt-8 lg:px-24"> */}
      {/*      <div className="w-full sm:w-1/3"> */}
      {/*        <StatsBox primaryText="+100%" secondaryText="Stats Information" /> */}
      {/*      </div> */}
      {/*      <div className="w-full sm:w-1/3"> */}
      {/*        <StatsBox primaryText="+100%" secondaryText="Stats Information" /> */}
      {/*      </div> */}
      {/*      <div className="w-full sm:w-1/3"> */}
      {/*        <StatsBox primaryText="+100%" secondaryText="Stats Information" /> */}
      {/*      </div> */}
      {/*    </div> */}
      {/*  </div> */}
      {/* </section> */}
      <section id="testimonials" className="py-20 lg:py-40">
        <div className="container mx-auto">
          <LabelText className="mb-8 text-gray-600 text-center">
            <FormattedMessage id="review.title" />
          </LabelText>
          <div className="flex flex-col md:flex-row md:-mx-3">
            {customerData.map(customer => (
              <div key={customer.customerName} className="flex-1 px-3">
                <CustomerCard customer={customer} />
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="container mx-auto py-12 lg:py-24 shadow-3xl rounded-lg text-center">
        <h3 className="text-2xl lg:text-4xl font-semibold">
          <FormattedMessage id="conclusion.title" />
        </h3>
        <p className="mt-8 text-lg lg:text-xl font-light">
          <FormattedMessage id="conclusion.caption" />
        </p>
        <p className="mt-8">
          <ButtonLink size="xl" href="https://app.blood-pressure-tracking.com/register">
            <FormattedMessage id="conclusion.button" />
          </ButtonLink>
        </p>
      </section>
    </Layout>
  );
};
